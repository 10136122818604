(function () {
  /**
   * The class 'no-js' is set by default on <html>. To indicate that
   * javaScript is enabled, we set the 'no-js' to 'js'
   */
  function jsDetection() {
    var docEl = document.documentElement;
    docEl.className = docEl.className.replace(/\bno-js\b/, 'js');
  }
  jsDetection();
})();
