/**
 * @author by Nils von Rymon-Lipinski
 * Defines responsive features for backgrounds and images. The media-queries are applied to the picture element
 * and the first matching source is chosen. Abbreviations are:
 * s (small), sr (small-retina)
 * ms (medium-small), msr (medium-small-retina)
 * m (medium), mr (medium-retina)
 * l (large), lr (large-retina)
 * xl (extra-large), xlr (extra-large-retina)
 */
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
  // Retina sizes (192dpi roughly equals retina factor 2x), only for use for background-images
  '--sr':
    '(max-width: 479px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 479px) and (min-resolution: 192dpi)',
  '--msr':
    '(max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 767px) and (min-resolution: 192dpi)',
  '--mr':
    '(max-width: 991px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 991px) and (min-resolution: 192dpi)',
  '--lr':
    '(max-width: 1199px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 1199px) and (min-resolution: 192dpi)',
  '--xlr':
    '(max-width: 1535px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 1535px) and (min-resolution: 192dpi)',
  // Non-retina sizes, used for picture-tag with img and background-images
  '--s': '(max-width: 479px)',
  '--ms': '(max-width: 767px)',
  '--m': '(max-width: 991px)',
  '--l': '(max-width: 1199px)',
  '--xl': '(max-width: 1535px)',
};
